import React from 'react'
import '../../components/styles/contact-form.scss'
import SegmentedControl from '../../components/common/SegmentedControl'
import Layout from '../../components/common/Layout'
import ContactForm from '../../components/common/ContactForm'

export default function ResourcesAE() {
    return (
        <Layout active='medical-science-liaison'
        title='PharmaEssentia Medical Affairs | Contact Medical Information'
        description='Request a meeting or call with your local Medical Science Liaison.'
        socialTitle='PharmaEssentia Medical Affairs | Contact Medical Information'
        socialDescription='Request a meeting or call with your local Medical Science Liaison.'
        pathname='/resources/medical-information'
        >
            <section className='col margin-padding-zero align-center'>
                <div className='inner'>
                    <h1>Resources</h1>
                    <SegmentedControl additionalClasses='space-between' buttons='resources' active='medical-science-liaison'/>
                    <h3>Contact Medical Information</h3>
                    <p>Please fill out the form below if you have a medical information question or would like to request a meeting or call with your local Medical Science Liaison.</p>
                </div>
                <ContactForm
                formId='medical-director'
                formClassName='bg-medical-director'
                msgPlaceholder='Additional information*'/>
            </section>
        </Layout>
    )
}